<template>
  <div class="setting-resource-type">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="header-team mt-3" v-else>
      <v-list
        dense
        class="list-ensemble mt-3"
        v-if="getTypeResource && getTypeResource.length"
      >
        <v-list-item
          class="list-ensemble-item mb-3 mr-3"
          v-for="(typeResource, index) in getTypeResource"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title class="flex center">
              <div
                class="text-capitalize font-text font-sz-14 bold-700 text-overflow-setting"
              >
                {{ typeResource.designation }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              class="icon-action mr-2"
              small
              outlined
              color="#5C2DD3"
              :title="
                $i18n.locale == 'fr'
                  ? 'Configuration des champs'
                  : 'Field configuration'
              "
              @click.prevent.stop="handleSettingClick(typeResource)"
            >
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn
              class="icon-action mr-2"
              small
              outlined
              color="#5C2DD3"
              :title="$t('update')"
              @click.prevent.stop="handleUpdateClick(typeResource)"
            >
              <font-awesome-icon class="img-h-19" icon="pencil-alt" />
            </v-btn>
          </v-list-item-action>
          <v-list-item-action class="ml-0">
            <v-btn
              class="icon-action"
              :title="$t('deleted')"
              small
              outlined
              color="#5C2DD3"
              @click.prevent.stop="handleDeleteClick(typeResource)"
            >
              <font-awesome-icon class="img-h-19" icon="trash-alt" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div v-else class="div-aucun-list">
        <div class="titre">
          {{
            $i18n.locale === 'fr'
              ? 'Aucune type de ressource'
              : 'No type resource'
          }}
        </div>
      </div>
      <div
        class="footer-style-table"
        v-if="getTypeResource && getTypeResource.length"
      >
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            class="per-page-element-style"
            v-model="per_page"
            :items="perPageList"
            @change="changePerPage"
            :label="
              $i18n.locale === 'fr' ? 'Eléments par page' : 'Rows per page'
            "
            outlined
            dense
            hide-details
            :no-data-text="
              $i18n.locale === 'fr' ? 'Aucun élément trouvé' : 'No items found'
            "
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-select>
        </div>
      </div>
    </div>
    <!-- delete type resource -->
    <v-dialog
      v-model="modalDeletedTypeRessource"
      max-width="700"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Supprimer un type de ressource'
                : 'Delete resource type'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalDeletedTypeRessource')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="color-black-light">
            {{
              $t('msgdeleted', {
                msg:
                  $i18n.locale === 'fr'
                    ? 'le type de ressource :'
                    : 'the resource type :'
              })
            }}
            <strong class="text-capitalize">
              {{
                typeResourceToDeleted && typeResourceToDeleted.designation
                  ? typeResourceToDeleted.designation
                  : ''
              }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getTypeResourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTypeResourceError" class="error-msg">
              <ul v-if="Array.isArray(getTypeResourceError)">
                <li v-for="(e, index) in getTypeResourceError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTypeResourceError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleDeletedTypeResource"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalDeletedTypeRessource')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- update type resource   -->
    <v-dialog
      v-model="modalUpdateTypeRessource"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Modifier un type de ressource'
                : 'Edit resource type'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalUpdateTypeRessource')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form
            ref="modalUpdateTypeRessource"
            class="mt-2"
            v-if="typeResourceToUpdate"
          >
            <v-row class="row">
              <v-col>
                <!-- NOM TYPE RESOURCE -->
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="typeResourceToUpdate.designation"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
                <!-- TYPE PROJET -->
                <v-select
                  v-model="typeResourceToUpdate.type_projet"
                  :label="
                    $i18n.locale == 'fr' ? 'Type du projet' : 'Project type'
                  "
                  dense
                  return-object
                  :no-data-text="
                    $i18n.locale == 'fr'
                      ? 'Aucun Type du projet trouvé'
                      : 'None Project type found'
                  "
                  item-text="name"
                  item-value="id"
                  :items="getProjectsTypes"
                  outlined
                  :loading="getProjectsTypesLoading"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale == 'fr'
                        ? 'Type du projet'
                        : 'Project type') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                ></v-select>
                <!-- DESCRIPTION -->
                <v-textarea
                  dense
                  label="Description"
                  v-model="typeResourceToUpdate.detail"
                  :persistent-placeholder="true"
                  rows="3"
                  outlined
                  color="#5C2DD3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getTypeResourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTypeResourceError" class="error-msg">
              <ul v-if="Array.isArray(getTypeResourceError)">
                <li v-for="(e, index) in getTypeResourceError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTypeResourceError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleValiderUpdateTypeRessource"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalUpdateTypeRessource')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      initLoading: true,
      typeResourceToDeleted: null,
      modalDeletedTypeRessource: false,
      typeResourceToUpdate: null,
      modalUpdateTypeRessource: false,
      typeResourceToUpdateOriginal: null,
      page: 1,
      per_page: 10,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 },
        { value: 50, text: 50 }
      ]
    }
  },

  methods: {
    ...mapActions([
      'fetchAllTypeResource',
      'updateTypeResource',
      'deletedTypeResource',
      'resetErrorTypeResource',
      'fetchAllResource',
      'fetchProjetsTypes'
    ]),
    pagination(pagination) {
      this.page = pagination
      this.fetchAllTypeResource({
        page: this.page,
        per_page: this.per_page
      })
    },
    changePerPage() {
      this.fetchAllTypeResource({
        page: this.page,
        per_page: this.per_page
      })
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'modalUpdateTypeRessource') {
        this.$refs.modalUpdateTypeRessource.resetValidation()
      }
      this.resetFieldModal()
      this.resetErrorTypeResource()
    },
    resetFieldModal() {
      this.typeResourceToDeleted = null
      this.typeResourceToUpdate = null
    },
    handleDeleteClick(typeResource) {
      this.typeResourceToDeleted = typeResource
      this.modalDeletedTypeRessource = true
    },
    async handleDeletedTypeResource() {
      this.loading = true
      const response = await this.deletedTypeResource(
        this.typeResourceToDeleted.id
      )
      if (response) {
        this.closeDialog('modalDeletedTypeRessource')
        this.fetchAllTypeResource({
          page: this.page,
          per_page: this.per_page
        })
      }
      this.loading = false
    },
    handleUpdateClick(typeResource) {
      this.typeResourceToUpdate = { ...typeResource }
      this.typeResourceToUpdateOriginal = typeResource
      this.typeResourceToUpdate.type_projet = this.typeResourceToUpdate.type_projet_id
      this.modalUpdateTypeRessource = true
      if (!this.getResource || !this.getResource.length) {
        this.fetchAllResource({ active: 1 })
      }
    },
    async handleValiderUpdateTypeRessource() {
      if (this.$refs.modalUpdateTypeRessource.validate()) {
        this.loading = true
        const body = {
          designation: this.typeResourceToUpdate.designation,
          detail: this.typeResourceToUpdate.detail,
          type_projet_id:
            this.typeResourceToUpdate &&
            this.typeResourceToUpdate.type_projet &&
            this.typeResourceToUpdate.type_projet.id
              ? this.typeResourceToUpdate.type_projet.id
              : this.typeResourceToUpdate.type_projet
        }
        const response = await this.updateTypeResource({
          body,
          typeResource: this.typeResourceToUpdateOriginal
        })
        if (response) {
          this.fetchAllTypeResource({
            page: this.page,
            per_page: this.per_page
          })
          this.closeDialog('modalUpdateTypeRessource')
        }
        this.loading = false
      }
    },
    handleSettingClick(typeResource) {
      this.$router.push('/setting/resource/type-resources/' + typeResource.id)
    }
  },
  computed: {
    ...mapGetters([
      'getTypeResource',
      'getTypeResourceLoading',
      'getTypeResourceError',
      'getResource',
      'getProjectsTypes',
      'getProjectsTypesLoading',
      'countPaginationTypeResource'
    ]),
    totalPages() {
      if (this.countPaginationTypeResource) {
        return Math.ceil(this.countPaginationTypeResource / this.per_page)
      }
      return this.countPaginationTypeResource
    }
  },

  async mounted() {
    this.initLoading = true
    await this.fetchAllTypeResource({
      page: this.page,
      per_page: this.per_page
    })
    this.fetchProjetsTypes()
    this.initLoading = false
  }
}
</script>
<style scoped lang="scss">
.setting-resource-type {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      height: 30px;
      min-width: 30px;
    }
  }
  .list-ensemble-item {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-color: #d8d8d8 !important;
  }
  .list-ensemble {
    max-height: calc(100vh - 314px) !important;
    height: calc(100vh - 314px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-ensemble::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .list-ensemble::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-ensemble::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
}
</style>
<style lang="scss">
.setting-resource-type {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  }
}
</style>
